import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';

export enum SettingsTab {
  Main = 'main',
  Texts = 'texts',
  Layout = 'layout',
  Design = 'design',
  Support = 'support',
}

export enum DesignSubTab {
  BackgroundsBorders = 'backgroundsBorders',
  Buttons = 'buttons',
  Dividers = 'dividers',
  Inputs = 'inputs',
  Texts = 'texts',
}

export enum SettingsEvent {
  TabChanged = 'tabChanged',
  SubTabChanged = 'subTabChanged',
}

export type SettingsEventHandler = ReturnType<typeof createEventHandler>;
